@import '../../assets/styles/global-styles.scss';

.menu{
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 178px;
  .logo{
    position: absolute;
    left: 32px;
    top: 22px;
    background-image: url(../../assets/images/60-Creek-Logo_isolated-BG_full-color.png);
    background-repeat: no-repeat;
    margin-top: 10px;
    width: 66px;
    height: 36px;
    background-size: 66px 36px;
  }
  .menu-wrapper{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 118px;
    left: 0px;
    margin-left: 16px;
    margin-right: 16px;
    width: 100%;
    height: calc(100% - 118px);
    background-color: white;
    .link-item
    {
      font-family: Raleway;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 40px;
      color: rgba(19, 22, 36, 0.6);
      letter-spacing: 0.1px;
      height: 40px;
      width: calc(100% - 44px);
      margin-bottom: 32px;
      padding: 0px 8px;
      text-align: left;
      text-decoration: none;

      border: none;
      
      &.selected{
        color: #2F64DC;
        background: #EAF2F8;
        border-radius: 10px;
      }
    }

  } 
}
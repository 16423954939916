@import '../../assets/styles/global-styles.scss';

.sixty-creek-signup{
  position: absolute;
  width: 100%;
  height: 100%;;
.g-centered-form-with-header{
  margin-top: 50px;
  margin-left: calc(50% - 200px);
    .g-form-container{
      width: 416px;
      height: 560px;
      position: relative;
      .g-input-box{
        .eye-icon{
          background-image: url(../../assets/images/eye-open-icon.svg);
          background-repeat: no-repeat;
          background-size: 16px 16px;
          position: absolute;
          width: 16px;
          height: 16px;
          top: 44px;
          right: 15px;
          cursor: pointer;
          z-index: 10;
        
          &.showing-password{
            background-size: 18px 18px;
            width: 18px;
            height: 18px;
            top: 43px;
            right: 14px;
            background-image: url(../../assets/images/eye-icon.svg);
          }
        }
        
        .g-error-label{
          &.smallest{
          margin-left: 16px;
          margin-top: 4px;
          margin-bottom: 4px;
          }
        }        
      }
      .g-link-item{
        &.small{
          margin-top:16px;
          margin-bottom: 32px;
          margin-left: 16px;
        }
      }
      .cancel-button{
        position: absolute;
        bottom: 40px;
        left: 32px;
        height: 40px;
        line-height: 40px;
        width: calc(50% - 40px);
        font-family: Raleway;
        font-size: 14px;
        text-align: right;
        text-decoration: underline;
        color: #366EE7;
        cursor: pointer;
      }
      .g-basic-button{
        position: absolute;
        bottom: 50px;
        width: calc(50% - 40px);
        cursor: pointer;
        &.next-button{
          right: 32px;
        }
        &.previous-button{
          left: 32px;
        }
      }
      
    } 
  }
}

.g-basic-label{
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(19, 22, 36, 0.6);
  text-align: left;
  &.italics{
    font-style: italic;
  }
  &.smallest{
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.4px;  
  }
  &.small{
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
  &.medium{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
  &.large{
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
}

.g-instruction-block{
  font-family: Raleway;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #131624;
  margin-left: auto;
  margin-right: auto;
}

.g-error-label{
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #D95746;
  text-align: left;
  font-style: italic;
  &.smallest{
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
  }
  &.small{
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
  &.medium{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
  &.large{
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
}

.g-link-item{
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;
  color: #366EE7;
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  margin-left: 0px;
  &.underlined{
    text-decoration: underline;
  }
  &.small{
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
  &.medium{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
  &.large{
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
}

.g-page-background{
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: #F5F7FA;
  width: 100%;
  height: 100%;
}

.g-page-background-with-nav{
  position: absolute;
  left: 179px;
  top: 0px;
  background-color: #F5F7FA;
  width: calc(100% - 178px);
  height: 100%;
  display: flex;
  flex-direction: column;

  .g-page-header{
    height: 104px;
    width: 100%;
    margin-left: 77px;
    .g-page-title{
      line-height: 104px;
      font-family: Raleway;
      font-size: 34px;
      text-align: left;
      color: #000000;
    }
  }

  .g-page-content{
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    .g-page-content-standard{
      margin-left: 77px;
      margin: 33px 80px;
      background-color: white;
      border-radius: 10px;
      width: calc(100% - 80px);
    }

    .g-page-content-column{
      display: flex;
      flex-direction: column;
      margin-right: 30px;
      &.column-one{
        padding-left: 77px;
      }
      .g-page-section{
        width: calc(100% - 100px);
        height: calc(100% - 32px);
        margin-bottom: 32px;
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 32px;
        background-color: white;
        border-radius: 10px;
        .g-section-header{
          height: 72px;
          width: 100%;
          .g-section-title{
            padding-top: 32px;
            width: 100%;
            text-align: left;
            font-family: Raleway;
            font-size: 24px;
            letter-spacing: 0.18px;
            color: #131624;      
          }
        }
    
      }
    }
  }
}
.g-centered-form-with-header{
  position: absolute;
  left: 0px;
  width: 100%;
  top: 85px;
  height: calc(100vh - 85px);
  display: flex;
  justify-content: center;
  align-items: center;

  .g-form-container{
    position: relative;
    background-color: white;
    border-radius: 10px;
    flex-shrink: 0;
    padding: 32px;
    display: flex;
    flex-direction: column;
    .g-caption{
      position: relative;
      font-family: Raleway;
      font-style: normal;
      font-weight: normal;
      font-size: 34px;
      line-height: 36px;
      color: #131624;
      width: 100%;
      height: 0px;
      top: -106px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }

    .g-form-frame{
      background-color: white;
      border-radius: 10px;
    }

    .g-form-line{
      display: flex;
      width: 100%;
      .g-half-input-box{
        margin-bottom: 24px;
        position: relative;
        width: calc(50% - 10px);
        margin-left: 10px;
        &:first-child{
          margin-right: 10px;
          margin-left: 0px;
        }
        .g-input-label{
          margin-bottom: 7px;
          margin-left: 10px;
          font-family: Raleway;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.25px;
          text-align: left;
          color: rgba(19, 22, 36, 0.6);
          height: 20px;
        }
        .g-input-container{
          background: #FFFFFF;
          border: 1px solid #A7D8FA;
          box-sizing: border-box;
          border-radius: 10px;
          height: 48px;
          padding: 16px;
          width: 100%;
          outline: none;
          &.combobox{
            border: none;
            padding: 0px;
          }
          &input{
            &::placeholder{
              font-family: Raleway;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.5px;
              color: rgba(19, 22, 36, 0.6);  
            }
            outline:  none;
          }
          .rw-widget{
            font-size: 12px;
            .rw-widget-container {
              background-color: #fff;
              border: #A7D8FA 1px solid;
              border-radius: 10px;
              .rw-select-bordered {
                border-left: #A7D8FA 1px solid;
              }
          }
          }
          input{
            width: 95%;
            height: 48px;
          }
          .rw-select-list-label{
            text-align: left;
            input{
              width: auto;
            }
          }
          .rw-popup-container{
            text-align: left;
          }
        }      
      }
    }

    .g-linear-input-box{
      display: flex;
      height: 60px;
      
      .checkbox{
        margin-right: 10px;
      }
      .g-linear-input-item{
        line-height: 46px;
      }
    }
    .g-input-box{
      margin-bottom: 24px;
      position: relative;
      width: 100%;
      &.error{
        margin-bottom: 0px;
        .error-label{
          margin-top: 4px;
          margin-bottom: 4px;
          margin-left: 0px;
          height: 16px;
          font-family: Raleway;
          font-style: normal;
          font-weight: normal;
          color: #D95746;
          text-align: left;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.4px;
        }
      }
      .g-input-label{
        margin-bottom: 7px;
        margin-left: 10px;
        font-family: Raleway;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        text-align: left;
        color: rgba(19, 22, 36, 0.6);
        height: 20px;
      }
      .g-input-container{
        background: #FFFFFF;
        border: 1px solid #A7D8FA;
        box-sizing: border-box;
        border-radius: 10px;
        height: 48px;
        padding: 16px;
        width: 100%;
        outline: none;
        &input{
          &::placeholder{
            font-family: Raleway;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.5px;
            color: rgba(19, 22, 36, 0.6);  
          }
          outline: none;
        }
        .rw-widget{
          font-size: 12px;
        }
        input{
          width: 95%;
          height: 24px;
        }
        .rw-select-list-label{
          text-align: left;
          input{
            width: auto;
          }
        }
        .rw-popup-container{
          text-align: left;
        }
      }
    }
  }
}

.g-basic-button{
  height: 40px;
  background: #ABC4FF;
  border-radius: 10px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 40px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #FFFFFF;
  cursor: pointer;
  &.enabled{
    background-color: #366EE7;
  }
}

.g-link-button{
  height: 40px;
  background: white;
  border: 1px solid #366EE7;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 40px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  text-decoration: none;
  color: #366EE7;
  cursor: pointer;
}

.data-table-list{
  .data-table-list-items{
    width: 100%;
    .data-table-list-row{
      width: 100%;
      display: flex;
      height: 60px;
      min-height: 30px;
      border-bottom: solid 1px #EAF2F8;
      
      .detail-item{
        margin-top: 9px;
        margin-bottom: 9px;
        margin-left: 7px;
        height: 32px;
        text-align: left;
        line-height: 20px;
        :first-child{
          margin-left: 0px;
        }
        &.contact-info{
          display: flex;
          .facebook{
            margin-top: 0px;
          }
          .email{
            margin-top: 3px;
            margin-left: 24px;
          }
          .phone{
            margin-top: 0px;
            margin-left: 24px;
          }
        }
        .detail-item-text{
          line-height: 28px;
          height: 28px;
          font-family: Raleway;
          font-size: 14px;
          text-align: left;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: left;
          width: auto;
          color: rgba(0, 0, 0, 1);
          &.framed{
            background: #EAF2F8;
            border-radius: 10px;
            padding: 8px;
          }
        }
        &.item-checkbox{
          margin-top: 14px;
          margin-left: 0px;
        }
      }
    }
    .none-found{
      margin-top: 50px;
    }
  }
}
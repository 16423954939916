@import '../../assets/styles/global-styles.scss';

.sixty-creek-password-reset-sent{
  .g-centered-form-with-header{
    .g-form-container{
      width: 432px;
      height: 160px;
      padding: 24px;
      .g-instruction-block{
        margin-top: 0px;
        width: 406px;
        margin-bottom: 16px;
      }
      
      .g-basic-label{
        &.smallest{
          text-align: center;
          margin-bottom: 40px;
        }
      }
      
      .g-link-button{
        width: 100%;
      }
    }
  }
}


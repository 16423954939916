@import '../../assets/styles/global-styles.scss';

.your-account{
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  .g-basic-button{
    width: 200px;
    margin-left: calc(50% - 100px);
    margin-top: 100px;
  }
}
.add-campaign-form{
  width: 750px;
  height: 418px;
  margin: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: absolute;
  top: 220px;
  left: calc(50% - 325px);
  z-index: 99;
  background-color: white;

  .title-box{
    padding-top: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    text-align: center;
    height: 20px;
    line-height: 20px;
  }

  .campaign-sides-container{
    display:flex;
    .left-side,
    .middle-side,
    .right-side{
      width: 250px;
      height: calc(100% - 50px);
      margin-top: 20px;
      margin-bottom: 20px;
      .label{
        text-align: left;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-size: 12px;
        color: #333333;
      }
      .input-box,
      .inline-input-box{
        display: flex;
        flex-direction: column;
        padding: 10px;
        width: 90%;
        .label{
          text-align: left;
          font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
          font-size: 12px;
          color: #333333;
        }
        .inline-input-container{
          height: 30px;
          input{
            width: 20px;
            height: 20px;
            margin-top: 2px;
          }
        }
        .input-container{
          height: 30px;
          .rw-widget{
            font-size: 12px;
          }
          input{
            width: 95%;
            height: 24px;
          }
          .rw-select-list-label{
            text-align: left;
            input{
              width: auto;
            }
          }
          .rw-popup-container{
            text-align: left;
          }
          textarea{
            width: 95%;
            height: 48x;
          }
        }
      }
      .inline-input-box{
        flex-direction: row;
        .label{
          margin-top: 8px;
        }
      }
    }
    .left-side,
    .middle-side{
      padding-right: 8px;
      border-right: 1px solid darkgray;
    }
    .right-side{
      padding-left: 10px;
    }
  }
  .add-button{
    position: absolute;
    bottom: 10px;
    right: 10px;
    height: 20px;
    padding: 10px;
    font-size: 14px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border: 1px solid gray;
    color: lightgray;
    &.enabled{
      cursor: pointer;
      border: 1px solid green;
      color: darkgreen;
      &:hover{
        border: none;
        background-color: green;
        color: white;
      }
    }
  }
}
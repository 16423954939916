@import '../../../../assets/styles/global-styles.scss';

.sixty-creek-sortable-header-cell{
  display: flex;
  height: 16px;
  margin-top: 9px;
  margin-bottom: 9px;
  margin-left: 10px;
  &.no-margin{
    margin-left: 0px;
  }
  .header-cell-title{
    font-family: Raleway;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-align: left;
    color: rgba(19, 22, 36, 0.6);
    text-transform: uppercase;
  }
  .header-cell-sort-button{
    margin-top: -2px;
    margin-left: 2px;
    // width: 8px;
    // height: 4px;
    transform: rotate(180deg);
    cursor: pointer;
    &.sort-ascending{
      transform: rotate(180deg);
    }
    &.sort-descending{
      // margin-top: 6px;
      // margin-left: 6px;
      transform: rotate(0deg);
    }
  }
}


@import '../Signup.scss';

.signature-label{
  position: relative;
  height: 14px;
  margin-bottom: 0px;
  margin-top: 10px;
  margin-left: 10px;
  span{
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.25px;
    text-align: left;
    color: rgba(19, 22, 36, 0.6);
    height: 20px;
  }
  .signature{
    position: absolute;
    left: 0;
    top: 0;
  }
  .clear{
    position: absolute;
    right: 20px;
    top: 0;
    text-decoration: underline;
    cursor: pointer;
  }
}
.signature-box{
  margin-top: 5px;
  width: calc(100% - 10px);
  height: 100px;
  border: 1px solid #366EE7;
  border-radius: 10px;
}

.sixty-creek-signup{
  .g-centered-form-with-header{
    .g-form-container{
      .g-link-item{
        &.small{
          margin-top:0px;
          margin-bottom: 0px;
          margin-left: 0px;
        }
      }
    }
  }
}

@import '../../assets/styles/global-styles.scss';

.sixty-creek-password-reset{
  .g-centered-form-with-header{
    .g-form-container{
      width: 416px;
      height: 248px;
      .g-instruction-block{
        width: 290px;
        margin-bottom: 21px;
      }
      
      .g-input-box{
        margin-bottom: 32px;
        .g-error-label{
          &.smallest{
          margin-left: 16px;
          margin-top: 4px;
          margin-bottom: 12px;
          }
        }        
      }
      .g-link-item{
        &.small{
          margin-top:16px;
          margin-bottom: 32px;
          margin-left: 16px;
        }
      }
    }
  }
}
